/* global.css */
@import url('https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

/* Tailwind Layers */
@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@layer base{img {display: initial;}}
body {
  font-family: Gantari;
}

/* Loader.css */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #67baf1;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.screen-size {
  @apply max-w-screen-1440;
  @apply mx-auto;
}

.screen-size-425{
  @apply max-w-screen-425;
  @apply mx-auto;
}



.payment-details-container-list {
  @apply flex flex-row justify-between border-b py-3 border-[#0000001F];
}
